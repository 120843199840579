import React, { useEffect, useState } from 'react'
import AuthLayout from '../../../components/layouts/AuthLayout';
import Cookies from 'js-cookie'
import { Plus, Search } from 'lucide-react';
import axios from '../../../lib/axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, NavLink, useNavigate } from 'react-router-dom';



export const Equipment = () => {

  const access_token = Cookies.get('access_token');
  const navigate = useNavigate();
  // const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [equipments, setEquipments] = useState([]);
  
  useEffect(() => {
    
    getEquipmentList(access_token)
   
  }, [access_token]);
  
  
  
  const getEquipmentList = async (access_token) => {
  
    axios.defaults.headers.Authorization = `Bearer ${access_token}`
    await axios.get('/sigees/equipment/all')
        .then((res) => {
          setEquipments(res.data.response.data)
        })
        .catch((error) => {
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {
  
                const errorMsg = typeof messages === 'string' ? messages : 'An unexpected error occurred';
  
                if (errorMsg === 'jwt expired') {
                    window.location.reload();
                } else {
                    toast.error(errorMsg);
                }
            }
        })
  }

  return (
    <AuthLayout>
        <div className="space-y-6">
        <div className="flex justify-between items-center pr-3">
          <h1 className="text-2xl font-semibold text-gray-900">Equipements</h1>
          <NavLink to="/equipment/add" className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-700">
            <Plus className="w-5 h-5" />
            <span>Ajouter un équipement</span>
          </NavLink>
        </div>

      <div className="bg-white shadow rounded-lg">
        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search equipment..."
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code Eq</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dernière lecture</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Installation Date</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {equipments.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate(`/equipment/${item.eq_code}/data`)}
                >

                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.eq_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.eq_type}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.eq_code}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      item.eq_status === 'active' ? 'bg-green-100 text-green-800' :
                      item.eq_status === 'inactive' ? 'bg-red-100 text-red-800' :
                      item.eq_status === 'maintenance' ? 'bg-orange-100 text-orange-800' :
                      item.eq_status === 'deleted' ? 'bg-red-100 text-red-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {item.eq_status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.eq_lastDataReceived ? moment(item.eq_lastDataReceived).fromNow() : "No last reading"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.eq_location}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* {selectedEquipment && (
        <EquipmentModal
          equipment={selectedEquipment}
          onClose={() => setSelectedEquipment(null)}
        />
      )} */}
    </div>
    </AuthLayout>
  )
}
