import React from 'react'
import AuthLayout from '../../components/layouts/AuthLayout'

export const Users = () => {
  return (
      <AuthLayout>
        <div>Users</div>
    </AuthLayout>
  )
}
