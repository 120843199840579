import React, { useState } from 'react'
import RecoveryLayout from '../../components/layouts/RecoveryLayout'
import { useAuth } from '../../contexts/auth'
import { useForm } from 'react-hook-form'
import ErrorsField from '../../components/errors/ErrorsField'
import { Link } from 'react-router-dom'
import SubmitButton from '../../components/button/SubmitButton'

export const ConfirmPassword = () => {
  
  // const router = useRouter();
  // const email = router.query.confirm_password;

  const { register, formState: { errors }, handleSubmit, } = useForm()
  const { showConfirmCodeRequest, sendConfirmNewPasswordAndResetCode } = useAuth()

  const [ isLoading, setIsLoading ] = useState([])


  const onSubmit = async (data) => {
    sendConfirmNewPasswordAndResetCode(data)
  }

  return (
    <RecoveryLayout
      link={"/login"}
      linkTitle={"Log in"}
      title={"Reset password "}
      question={"Return to login? "}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=""
      >
        <div>

          <input
            {...register('verification_code', { required: true })}
            type="number"
            className={`w-full mt-2 px-3 py-2 ${errors.verification_code && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.verification_code} message={"Code is required"} />
        </div>      

        <div>
          <label className="font-medium">
            Password
          </label>
          <input
            {...register('password', { required: true })}
            type="password"
            className={`w-full mt-2 px-3 py-2 ${errors.password && "border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-blue-600 shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.password} message={"Password is required"} />
        </div>

        <div>
          <label className="font-medium">
            Password
          </label>
          <input
            {...register('confirm_password', { required: true })}
            type="password"
            className={`w-full mt-2 px-3 py-2 ${errors.confirm_password && "border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-blue-600 shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.confirm_password} message={"Confirm password is required"} />
        </div>
        <Link to="/email" className=' right-0  text-blue-700 underline'>Resend code</Link>
        <SubmitButton text={"Sign up"} />
        
      </form>
    </RecoveryLayout>
  )
}
