import React from 'react'
import { useAuth } from "../../contexts/auth";
import ErrorsField from "../../components/errors/ErrorsField";
import { useForm } from "react-hook-form";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import RegistrationLayout from '../../components/layouts/RegistrationLayout';
import fr from 'react-phone-input-2/lang/fr.json'
import 'react-phone-input-2/lib/style.css'
import SubmitButton from '../../components/button/SubmitButton';


export const Register = () => {
  
  
  const { register, formState: { errors }, handleSubmit, } = useForm()
  const { registerAuth } = useAuth()

  // const [ errs, setErrs ] = useState([])
  // const [ isLoading, setIsLoading ] = useState([])
  const [ tel, setTel ] = useState("")


  const onSubmit = async (data) => {
    registerAuth(data, tel)
  }

  return (
    <RegistrationLayout
      link={"/login"}
      linkTitle={"Log in"}
      title={"Create an account"}
      question={"Already have an account? "}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5"
      >
        <div>
          <label className="font-medium">
            Email
          </label>
          <input
            {...register('email', { required: true })}
            type="email"
            className={`w-full mt-2 px-3 py-2 ${errors.email && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.email} message={"Email is required"} />
        </div>

        <div >
          <label className="font-medium mb-2">
            Mobile
          </label>
          <PhoneInput
            
            country={"ci"}
            value={tel}
            onChange={(tel) => setTel(tel)}
            // {...register('tel', { required: true })}
            // onlyCountries={[ 'ci' ]}
            localization={fr}
            masks={{ ci: '.. .. .. ....', }}
            //   disableCountryCode
            enableSearch={true}
            countryCodeEditable={false}
            
            inputProps={{
              name: 'Mobile',
              required: true,
              autoFocus: true,
              className: "w-full bg-gray-100 p-3 px-12 rounded-lg",
            }}
          />

        </div>

        <div className="justify-between grid grid-cols-1 md:grid-cols-2">
          <div className="mr-1">
            <label className="font-medium">
              First name
            </label>
            <input
              {...register('nom', { required: true })}
              type="text"
              className={`w-full mt-2 px-3 py-2 ${errors.nom && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-blue-600 shadow-sm rounded-lg`}
            />
            <ErrorsField errors={errors.tel} message={"First name is required"} />
          </div>

          <div className="ml-1">
            <label className="font-medium">
              Last name
            </label>
            <input
              {...register('prenoms', { required: true })}
              type="text"
              className={`w-full mt-2 px-3 py-2 ${errors.prenoms && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-blue-600 shadow-sm rounded-lg`}
            />
            <ErrorsField errors={errors.prenoms} message={"Last name is required"} />
          </div>
        </div>

        <div>
          <label className="font-medium  ">
            Password
          </label>
          <input
            {...register('password', { required: true })}
            type="password"
            className={`w-full mt-2 px-3 py-2 ${errors.password && "border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-blue-600 shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.password} message={"Password is required"} />
        </div>
        <SubmitButton text={"Sign up"} />
        
      </form>
    </RegistrationLayout>
  )
}
