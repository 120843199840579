import Axios from 'axios'


// let urls = {
//     development: process.env.REACT_PUBLIC_BACKEND_URL,
//     production: process.env.REACT_PUBLIC_BACKEND_PROD_URL,
// }

const axios = Axios.create({
    baseURL: "https://api-sigees.taligne.com/",
    // baseURL: "http://localhost:5200",
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',

    },
    withCredentials: true, // Important pour inclure les cookies
    // withCredentials: true,
})

export default axios
