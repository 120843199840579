import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import axios from '../lib/axios';
import { toast } from 'react-toastify';



const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [ user, setUser ] = useState(null)
    const [ loading, setLoading ] = useState(true)
    // const [ middleware, setMiddleware ] = useState(null)
    // const [ redirectUser, setRedirectUser ] = useState(null)
    const [ showConfirmCodeRequest, setShowConfirmCodeRequest ] = useState(false)
    const [ emailWhereCodeWasSend, setEmailWhereCodeWasSend ] = useState(null)

   


    useEffect(() => {
        async function loadUserFromCookies() {
            const access_token = Cookies.get('access_token')           
            
            if (!access_token) {
                setUser(null);
                setLoading(false);
                return;
            }
            

            // Si les données utilisateur sont déjà chargées, éviter les appels redondants
            if (user) {
                // On va vérifier si le token est prèsque expirer
                // Si c'est le cas alors nous allons le reinitialisé 
                setLoading(false);
                return;
            }

            axios.defaults.headers.Authorization = `Bearer ${access_token}`
            await axios.get('/auth/user/profile')
                .then((res) => {     
                    const user = res.data.user
                    setUser(user);                    
                })
                .catch((errors) => {                    
                    setUser(null);
                    // console.log("No token in the cookies", errors?.response?.data?.response.message)
                })

            
            setLoading(false)
        }
        loadUserFromCookies()
    }, [ navigate, user ])

    const login = async (email, password) => {
        try {
            const response = await axios.post('/auth/user/login', { email, password });

            const { data, tokens } = response.data;
            const { access, refresh } = tokens;

            // Set access_token and refresh_token cookies
            Cookies.set('access_token', access.token);
            Cookies.set('access_expires', access.expires);
            Cookies.set('refresh_token', refresh.token);
            Cookies.set('refresh_expires', refresh.expires);
            
            setUser(data);
            navigate('/dashboard');           

        } catch (error) {
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {

                const errorMsg = typeof messages === 'string' ? messages : error?.response?.data?.message;
                if (errorMsg === 'jwt expired') {
                    window.location.reload();
                }
                toast.error(errorMsg);
            }
            
        }
        
    }


    const registerAuth = async (userData, tel) => {
        try {

            const { nom, prenoms, email, password } = userData;
            const response = await axios.post('/auth/user/register', { 
                nom,
                prenoms,
                email, 
                password,
                tel,
            });

            const { data, tokens, message, } = response.data;
            const { access, refresh } = tokens.response.data;
            // Show message to user
            toast.success(message);


            // Set access_token and refresh_token cookies
            Cookies.set('access_token', access.token);
            Cookies.set('access_expires', access.expires);
            Cookies.set('refresh_token', refresh.token);
            Cookies.set('refresh_expires', refresh.expires);
            setUser(data);
            navigate('/dashboard');


        } catch (error) {
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {

                const errorMsg = typeof messages === 'string' ? messages : error?.response?.data?.message;

                if (errorMsg === 'jwt expired') {
                    toast.error('Session expired. Redirecting to login.');
                    window.location.reload();
                } else {
                    toast.error(errorMsg);
                }
            }           
        }
        
    }


    const sendToEmailPasswordResetCode = async (userData) => {
        try {

            const res = await axios.post('/auth/user/reset-password', {                 
                email: userData,                
            });

            const { statusCode, response } = res.data;
            // const { access, refresh } = response.response.data;
            // Show message to user
            if (statusCode === 200) {
                toast.success(response.message);
                setEmailWhereCodeWasSend(userData)
                setShowConfirmCodeRequest(true)
                navigate('/confirm_password')
            }


        } catch (error) {
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {

                const errorMsg = typeof messages === 'string' ? messages : error?.response?.data?.message;

                if (errorMsg === 'jwt expired') {
                    toast.error('Session expired. Redirecting to login.');
                    window.location.reload();
                } else {
                    toast.error(errorMsg);
                }
            }        
        }
        
    }


    const sendConfirmNewPasswordAndResetCode = async (userData) => {
        try {

            const { password, confirm_password, verification_code  } = userData;
            const res = await axios.put('auth/user/confirm-password', {                 
                email: emailWhereCodeWasSend,                
                password, 
                confirm_password,
                verification_code, 
            });

            const { statusCode, response } = res.data;
            // Show message to user
            if(statusCode === 200) {
                toast.success(response.message);
                setShowConfirmCodeRequest(false)
                navigate('/login')
            }

        } catch (error) {
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {

                const errorMsg = typeof messages === 'string' ? messages : error?.response?.data?.message;

                if (errorMsg === 'jwt expired') {
                    toast.error('Session expired. Redirecting to login.');
                    window.location.reload();
                } else {
                    toast.error(errorMsg);
                }
            }
        }
        
    }


    const logout = async () => {

        try {
            
            const refresh_token = Cookies.get('refresh_token');
            const access_token = Cookies.get('access_token')
            
            if (refresh_token) {
                axios.defaults.headers.Authorization = `Bearer ${access_token}`;
                    await axios.post('/auth/user/logout', 
                        {
                            refresh_token: refresh_token,
                        })
                        .then((res)=>{
                            Cookies.remove('access_token')
                            Cookies.remove('refresh_token')
                            setUser(null)
                            toast.success(res.data.response.message)
                            delete axios.defaults.headers.Authorization
                            

                        })
                        .catch((errors)=>{
                            toast.error(errors?.message)
                        })
            }
            else {
                Cookies.remove('access_token')
                Cookies.remove('refresh_token')
                setUser(null)
                delete axios.defaults.headers.Authorization 
                
            }
            window.location.pathname = '/login'
            
        } catch (error) {
            console.error('Error during logout:', error);
            delete axios.defaults.headers.Authorization 
        }
    }


    const changePassword = async (userData) => {
        try {

            const access_token = Cookies.get('access_token')
            axios.defaults.headers.Authorization = `Bearer ${access_token}`

            const { old_password, password, confirm_password } = userData;
            const res = await axios.put('auth/user/change-password', {
                old_password,
                password,
                confirm_password,
                uuid: user.uuid,
            });

            const { statusCode, response } = res.data;
            // Show message to user
            if (statusCode === 200) {
                toast.success(response.message);
            }

        } catch (error) {
           
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {

                const errorMsg = typeof messages === 'string' ? messages : error?.response?.data?.message;

                if (errorMsg === 'jwt expired') {
                    toast.error('Session expired. Redirecting to login.');
                    window.location.reload();
                } else {
                    toast.error(errorMsg);
                }
            }
        }

    }
    
    
    const updateProfile = async (userData) => {
        try {

            const access_token = Cookies.get('access_token')
            axios.defaults.headers.Authorization = `Bearer ${access_token}`

            const { old_password, password, confirm_password } = userData;
            const res = await axios.put('auth/user/change-password', {
                old_password,
                password,
                confirm_password,
                uuid: user.uuid,
            });

            const { statusCode, response } = res.data;
            // Show message to user
            if (statusCode === 200) {
                toast.success(response.message);
            }

        } catch (error) {
            const messages = error?.response?.data?.response?.message;
            if (Array.isArray(messages)) {
                messages.forEach((message) => toast.error(message.msg));
            } else {

                const errorMsg = typeof messages === 'string' ? messages : error?.response?.data?.message;

                if (errorMsg === 'jwt expired') {
                    toast.error('Session expired. Redirecting to login.');
                    window.location.reload();
                } else {
                    toast.error(errorMsg);
                }
            }
        }

    }


  return (
      <AuthContext.Provider 
        value={{ 
            isAuthenticated: !!user, 
            user, 
            // middleware, 
            // redirectUser, 
            showConfirmCodeRequest,
            sendToEmailPasswordResetCode, 
            login, 
            registerAuth, 
            loading, 
            logout,
            sendConfirmNewPasswordAndResetCode,
            changePassword,
            updateProfile,
        }}
    >
          {children}
      </AuthContext.Provider>
  )
}


export const useAuth = () => useContext(AuthContext)


