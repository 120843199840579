import React, { useState, useEffect } from 'react';
import axios from '../../../lib/axios';
import { toast } from 'react-toastify';
import {ExportRawDataModal } from './ExportDatas/ExportRawDataModal';


export const RawData = ({ equipment }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState('DESC');
  const [startDate, setStartDate] = useState('2023-01-01'); // À adapter selon vos besoins
  const [endDate, setEndDate] = useState('2023-01-31');   // À adapter selon vos besoins

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(`sigees/equipment/eq_code/${equipment.eq_code}/startDate/${startDate}/endDate/${endDate}/page/${page}/limit/${limit}/order/${order}`
      );
      setData(response.data.response.data.equipmentData);
      setTotalPages(Math.ceil(response.data.response.data.totalPages / limit));

    } catch (error) {
        const messages = error?.response?.data?.response?.message;
        if (Array.isArray(messages)) {
            messages.forEach((message) => toast.error(message.msg));
        } else {

            const errorMsg = typeof messages === 'string' ? messages : 'An unexpected error occurred';

            if (errorMsg === 'jwt expired') {
                window.location.reload();
            } else {
                toast.error(errorMsg);
            }
        }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  },  [currentPage, limit, order, startDate, endDate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full overflow-x-auto">
        {/* ICI */}
        <div className="flex space-x-4 mb-4">              
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  fetchData(currentPage); // Met à jour le tableau
                }}
                className="border rounded-md px-2 py-1"
              />
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  fetchData(currentPage); // Met à jour le tableau
                }}
                className="border rounded-md px-2 py-1"
              />
              <input
                type="number"
                min="5"
                max="1000"
                value={limit}
                onChange={(e) => {
                  const newLimit = Number(e.target.value);
                  if (newLimit <= 1000) {
                    setLimit(newLimit);
                    fetchData(currentPage); // Met à jour le tableau
                  }
                }}
                className="border rounded-md px-2 py-1"
                placeholder="Limit (5-1000)"
              />
              <select
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                  fetchData(currentPage); // Met à jour le tableau
                }}
                className="border rounded-md px-2 py-1"
              >
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
              </select>

              <ExportRawDataModal data={data} equipment={equipment}  />
        </div>
        {/* ICI */}
      {loading ? (
        <div className="text-center py-4">Chargement...</div>
      ) : (
        <>
            
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-50">
              <tr>
               
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Courant Ph1</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Courant Ph2</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Courant Ph3</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tension UAB</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tension UBC</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tension UCA</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fréquence</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fact. Puissance</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Puiss. Active</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Puiss. Réactive</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Énergie Active</th>
                <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Énergie Réactive</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data?.map((row, index) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{new Date(row.ed_timestamp).toLocaleString()}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_courant_phase_1}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_courant_phase_2}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_courant_phase_3}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_tension_composee_uab}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_tension_composee_ubc}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_tension_composee_uca}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_frequency}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_facteur_puissance}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_puissance_active}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_puissance_reactive}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_energie_active}</td>
                  <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{row.ed_energie_reactive}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center items-center space-x-2 mt-4 mb-8">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 border rounded-md ${
                currentPage === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Première
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 border rounded-md ${
                currentPage === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Précédente
            </button>
            <span className="px-4 py-2">
              Page {currentPage} sur {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 border rounded-md ${
                currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Suivante
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 border rounded-md ${
                currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Dernière
            </button>
          </div>
        </>
      )}
    </div>
  );
};
