
const ErrorsField = ({ errors, message }) => {
  return (
    <>
        {errors?.type === "required" && (
            <p className="text-xs font-semibold text-red-500" role="alert">{message}</p>   
        )}
    </>

  )
}

export default ErrorsField