import React, { useState } from 'react'
import { GraphTabHour } from './GraphTabs/GraphTabHour';
import { GraphTabDay } from './GraphTabs/GraphTabDay';
import { GraphTabMonth } from './GraphTabs/GraphTabMonth';

export const GraphTabs = ({equipment}) => {
  const [activeTab, setActiveTab] = useState('graphTab-1'); // État pour suivre l'onglet actif
  return (
    <div>
      <div className=" mt-5">
            <div className="tabs tabs-underline">
                <input type="radio" id="idGraphTab-1" name="tab-4" className="tab-toggle" checked={activeTab === 'graphTab-1'} onChange={() => setActiveTab('graphTab-1')} />
                <label htmlFor="idGraphTab-1" className="tab ">Heure</label>

                <input type="radio" id="idGraphTab-2" name="tab-4" className="tab-toggle" checked={activeTab === 'graphTab-2'} onChange={() => setActiveTab('graphTab-2')} />
                <label htmlFor="idGraphTab-2" className="tab ">Jours</label>

                <input type="radio" id="idGraphTab-3" name="tab-4" className="tab-toggle" checked={activeTab === 'graphTab-3'} onChange={() => setActiveTab('graphTab-3')} />
                <label htmlFor="idGraphTab-3" className="tab ">Mois</label>
            </div>

            <div className="tab-content mt-5">
                {activeTab === 'graphTab-1' && (
                    <div id="graphTab-1" className="tab-pane">
                        <GraphTabHour equipment={equipment} />
                    </div>
                )}
                {activeTab === 'graphTab-2' && (
                    <div id="graphTab-2" className="tab-pane">
                        <GraphTabDay equipment={equipment} />
                    </div>
                )}
                {activeTab === 'graphTab-3' && (
                    <div id="graphTab-3" className="tab-pane">
                        <GraphTabMonth equipment={equipment} />
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}
