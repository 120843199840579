import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from '../../../lib/axios';
import { toast } from 'react-toastify';
import AuthLayout from '../../../components/layouts/AuthLayout';
import moment from 'moment';
import { RawData } from '../../../components/components/Equipment/RawData';
import { GraphTabs } from '../../../components/components/Equipment/GraphTabs';
import { StatsTab } from '../../../components/components/Equipment/StatsTab';

export const EquipmentData = () => {

    // const navigate = useNavigate();
    const { eq_code } = useParams(); // Récupérer le paramètre eq_code
    const access_token = Cookies.get('access_token');
    
    // const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [equipment, setEquipment] = useState();
    
    const [activeTab, setActiveTab] = useState('tab-7'); // État pour suivre l'onglet actif
    
    useEffect(() => {
      
        getOneEquipment(access_token, eq_code)
     
    }, [access_token, eq_code]);
    
    
    
    const getOneEquipment = async (access_token, eq_code) => {
    
      axios.defaults.headers.Authorization = `Bearer ${access_token}`
      await axios.get(`sigees/equipment/${eq_code}`)
          .then((res) => {
            setEquipment(res.data.response.data)
          })
          .catch((error) => {
              const messages = error?.response?.data?.response?.message;
              if (Array.isArray(messages)) {
                  messages.forEach((message) => toast.error(message.msg));
              } else {
    
                  const errorMsg = typeof messages === 'string' ? messages : 'An unexpected error occurred';
    
                  if (errorMsg === 'jwt expired') {
                      window.location.reload();
                  } else {
                      toast.error(errorMsg);
                  }
              }
          })
    }

  return (
    <AuthLayout>
        <div>
            <div className="flex justify-between items-center py-4">
                <h1 className="text-2xl font-semibold text-gray-900">Données de l'équipment {equipment?.eq_name}</h1>
                
            </div>
            <div>
                {equipment &&

                <div>
                    <div className="bg-white rounded-lg shadow p-6">
                        <div className="flex flex-wrap justify-between">
                            <div className="w-1/2 sm:w-1/3 p-2">
                                <p className="text-sm font-medium text-gray-500">Code</p>
                                <p className="text-sm font-semibold text-gray-900">{equipment.eq_code}</p>
                            </div>
                            <div className="w-1/2 sm:w-1/3 p-2">
                                <p className="text-sm font-medium text-gray-500">Nom</p>
                                <p className="text-sm font-semibold text-gray-900">{equipment.eq_name}</p>
                            </div>
                            <div className="w-1/2 sm:w-1/3 p-2">
                                <p className="text-sm font-medium text-gray-500">Type</p>
                                <p className="text-sm font-semibold text-gray-900">{equipment.eq_type.charAt(0).toUpperCase() + equipment.eq_type.slice(1)}</p>
                            </div>
                            <div className="w-1/2 sm:w-1/3 p-2">
                                <p className="text-sm font-medium text-gray-500">Emplacement</p>
                                <p className="text-sm font-semibold text-gray-900">{equipment.eq_location}</p>
                            </div>
                            <div className="w-1/2 sm:w-1/3 p-2">
                                <p className="text-sm font-medium text-gray-500">Dernière lecture</p>
                                <p className="text-sm font-semibold text-gray-900">{moment(equipment.eq_lastDataReceived).fromNow()}</p>
                            </div>
                            <div className="w-1/2 sm:w-1/3 p-2">
                                <p className="text-sm font-medium text-gray-500">Statut</p>
                                <p className="text-sm font-semibold text-gray-900">
                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                        equipment.eq_status === 'active' ? 'bg-green-100 text-green-800' :
                                        equipment.eq_status === 'inactive' ? 'bg-red-100 text-red-800' :
                                        equipment.eq_status === 'maintenance' ? 'bg-orange-100 text-orange-800' :
                                        equipment.eq_status === 'deleted' ? 'bg-red-100 text-red-800' :
                                        'bg-yellow-100 text-yellow-800'
                                        }`}>
                                        {equipment.eq_status.charAt(0).toUpperCase() + equipment.eq_status.slice(1)}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>


                  
                    <div className="bg-white rounded-lg shadow p-3 mt-5">
                        <div className="tabs tabs-underline">
                            <input type="radio" id="tab-4" name="tab-3" className="tab-toggle" checked={activeTab === 'tab-7'} onChange={() => setActiveTab('tab-7')} />
                            <label htmlFor="tab-4" className="tab ">Données brutes</label>

                            <input type="radio" id="tab-5" name="tab-3" className="tab-toggle" checked={activeTab === 'tab-8'} onChange={() => setActiveTab('tab-8')} />
                            <label htmlFor="tab-5" className="tab ">Statistiques</label>

                            <input type="radio" id="tab-6" name="tab-3" className="tab-toggle" checked={activeTab === 'tab-9'} onChange={() => setActiveTab('tab-9')} />
                            <label htmlFor="tab-6" className="tab ">Données Graphiques</label>
                        </div>

                        <div className="tab-content mt-5">
                            {activeTab === 'tab-7' && (
                                <div id="tab-7" className="tab-pane">
                                    <RawData equipment={equipment} />
                                </div>
                            )}
                            {activeTab === 'tab-8' && (
                                <div id="tab-8" className="tab-pane">
                                    <StatsTab equipment={equipment} />        
                                </div>
                            )}
                            {activeTab === 'tab-9' && (
                                <div id="tab-9" className="tab-pane">
                                    <GraphTabs equipment={equipment} />
                                </div>
                            )}
                        </div>
                    </div>
             

                </div>


                
                }
            </div>
        </div>
    </AuthLayout>
  )
}
