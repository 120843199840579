import React from 'react'
import * as XLSX from 'xlsx'; 

export const ExportRawDataModal = ({data, equipment}) => {

  
const handleExport = (dataToExport, equipment) => {
 

  // Map the data to include headers
  const formattedData = dataToExport.map(item => {
    return {
      'Timestamp'         : new Date(item.ed_timestamp).toLocaleString() ,
      'Courant Ph1'       : item.ed_courant_phase_1,
      'Courant Ph2'       : item.ed_courant_phase_2,
      'Courant Ph3'       : item.ed_courant_phase_3,
      'Tension UAB'       : item.ed_tension_composee_uab,
      'Tension UBC'       : item.ed_tension_composee_ubc,
      'Tension UCA'       : item.ed_tension_composee_uca,
      'Fréquence'         : item.ed_frequency,
      'Fact. Puissance'   : item.ed_facteur_puissance,
      'Puiss. Active'     : item.ed_puissance_active,
      'Puiss. Réactive'   : item.ed_puissance_reactive,
      'Énergie Active'    : item.ed_energie_active,
      'Énergie Réactive'  : item.ed_energie_reactive,
    };
  });

  const ws = XLSX.utils.json_to_sheet(formattedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Données');
  const timestamp = new Date().toISOString().replace(/:/g, '_').replace(/-/g, '_').replace('Z', '');
  const sanitizedEqName = equipment.eq_name.replace(/[- ]/g, '_');
  XLSX.writeFile(wb, `${sanitizedEqName}_${equipment.eq_code}_RawData_${timestamp}.xlsx`);
};

  return (
    <div>
        
        <label className="btn  justify-end" htmlFor="modal-1">Export Data to Excel </label>
        <input className="modal-state" id="modal-1" type="checkbox" />
        <div className="modal">
            <label className="modal-overlay" htmlFor="modal-1"></label>
            <div className="modal-content flex flex-col gap-5">
                <label htmlFor="modal-1" className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</label>
                <h2 className="text-xl">Export Data to Excel </h2>
                <span>
                  Êtes-vous sûr de vouloir exporter les données vers un fichier Excel ?
                </span>
                <div className="flex gap-3">
                    <button className="btn btn-success btn-block" 
                      onClick={() => { handleExport(data, equipment);  }}
                    >
                      Exporter
                    </button>

                    <label className="btn btn-block " htmlFor="modal-1">
                      Annuler
                    </label>
                </div>
            </div>
        </div>
    </div>
  )
}
