import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/guest/Login";
import { Register } from "./pages/guest/Register";
import { Dashboard } from "./pages/auth/Dashboard";
import { Email } from "./pages/guest/Email";
import { ConfirmPassword } from "./pages/guest/ConfirmPassword";
import { Equipment } from "./pages/features/Equipment/Equipments";
import { Alert } from "./pages/features/Alert";
import { Souscription } from "./pages/features/Souscription";
import { Users } from "./pages/features/Users";
import { Settings } from "./pages/features/Settings";
import { NotFound } from "./pages/ErrorsPage/NotFound";
import { AddEquipment } from "./pages/features/Equipment/AddEquipment";
import { EquipmentData } from "./pages/features/Equipment/EquipmentData";

function App() {
  return (
    <div className="">
      <Routes>

        {/* Guest routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/email" element={<Email />} />
        <Route path="/confirm_password" element={<ConfirmPassword />} />

        {/* Protected routes */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Features routes */}
        {/* Equipment */}
        <Route path="/equipments" element={<Equipment />} />
        <Route path="/equipment/add" element={<AddEquipment />} />
        <Route path="/equipment/:eq_code/data" element={<EquipmentData />} />



        <Route path="/alerts" element={<Alert />} />
        <Route path="/souscriptions" element={<Souscription />} />
        <Route path="/users" element={<Users />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/*" element={<NotFound />} />

      </Routes>    
    </div>
  );
}

export default App;
