import React, { useState } from 'react'
import { StatsTabHour } from './StatTabs/StatsTabHour'
import { StatsTabDay } from './StatTabs/StatsTabDay'
import { StatsTabMonth } from './StatTabs/StatsTabMonth'

export const StatsTab = ({equipment}) => {

    const [activeTab, setActiveTab] = useState('statTab-1'); // État pour suivre l'onglet actif
  return (
    <div>
           
        <div className=" mt-5">
            <div className="tabs tabs-underline">
                <input type="radio" id="idStatTab-1" name="tab-4" className="tab-toggle" checked={activeTab === 'statTab-1'} onChange={() => setActiveTab('statTab-1')} />
                <label htmlFor="idStatTab-1" className="tab ">Heure</label>

                <input type="radio" id="idStatTab-2" name="tab-4" className="tab-toggle" checked={activeTab === 'statTab-2'} onChange={() => setActiveTab('statTab-2')} />
                <label htmlFor="idStatTab-2" className="tab ">Jours</label>

                <input type="radio" id="idStatTab-3" name="tab-4" className="tab-toggle" checked={activeTab === 'statTab-3'} onChange={() => setActiveTab('statTab-3')} />
                <label htmlFor="idStatTab-3" className="tab ">Mois</label>
            </div>

            <div className="tab-content mt-5">
                {activeTab === 'statTab-1' && (
                    <div id="statTab-1" className="tab-pane">
                        <StatsTabHour equipment={equipment} />
                    </div>
                )}
                {activeTab === 'statTab-2' && (
                    <div id="statTab-2" className="tab-pane">
                        <StatsTabDay equipment={equipment} />
                    </div>
                )}
                {activeTab === 'statTab-3' && (
                    <div id="statTab-3" className="tab-pane">
                        <StatsTabMonth equipment={equipment} />
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}
