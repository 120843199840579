import { AlertTriangle } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <AlertTriangle size={64} className="text-red-600 mr-2" /> 
      <h1 className="flex text-4xl font-bold text-red-600"> Page Non Trouvée</h1>
      <p className="mt-4 text-lg text-gray-700">Désolé, la page que vous recherchez n'existe pas.</p>
      <Link to="/" className="mt-6 px-4 py-2 text-white bg-[#009530] hover:bg-[#3dcd58] rounded duration-150">
       Retour à l'accueil
      </Link>
  </div>
  )
}
