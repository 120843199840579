import React from 'react'

const LoadingComponent = () => {
  return (
      <main className="w-full h-screen flex flex-col items-center justify-center">
          <div className="spinner-simple"></div>
      </main>
  )
}

export default LoadingComponent