import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../../contexts/auth'
import RecoveryLayout from '../../components/layouts/RecoveryLayout'
import { Link } from 'react-router-dom'
import SubmitButton from '../../components/button/SubmitButton'
import ErrorsField from '../../components/errors/ErrorsField'

export const Email = () => {
  const { register, formState: { errors }, handleSubmit, watch } = useForm()
  const { sendToEmailPasswordResetCode, showConfirmCodeRequestModal } = useAuth()

  const [ errs, setErrs ] = useState([])
  const [ isLoading, setIsLoading ] = useState([])


  const onSubmit = async (data) => {
      sendToEmailPasswordResetCode(data.email)
  }

return (
  <RecoveryLayout
        link={"/login"}
        linkTitle={"Log in"}
        title={"Sent password reset code"}
        question={"Return to login? "}
  >
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-5"
        >
            <div>
                <input
                    {...register('email', { required: true })}
                    type="email"
                    className={`w-full mt-2 px-3 py-2 ${errors.email && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
                />
                <ErrorsField errors={errors.email} message={"Email is required"} />
            </div>
            
            <SubmitButton text={"Send recovery code"} />
        </form>

  </RecoveryLayout>
)
}
