import React, { useEffect, useState } from 'react'
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import Chart from 'react-apexcharts';

export const GraphTabDay = ({equipment}) => {

      const [statistics, setStatistics] = useState([]);
      const [loading, setLoading] = useState(false);
      const [startDate, setStartDate] = useState('2023-01-01'); // À adapter selon vos besoins
      const [endDate, setEndDate] = useState('2023-03-01');   // À adapter selon vos besoins
      const [dataColumn, setDataColumn] = useState('ed_energie_reactive'); // Valeur par défaut
      const [global, setGlobal] = useState([]); // Valeur par défaut
  
    const fetchStatistics = async () => {
      try {
          setLoading(true);
          const response = await axios.get(`sigees/statistics/graph_by_day/${equipment.eq_code}/startDate/${startDate}/endDate/${endDate}/data_column/${dataColumn}`);
          setStatistics(response.data.response.data.datas_graph_by_day); // Assurez-vous que la structure des données est correcte
          setGlobal(response.data.response.data.global[0]); 
      } catch (error) {
          const messages = error?.response?.data?.response?.message;
          if (Array.isArray(messages)) {
              messages.forEach((message) => toast.error(message.msg));
          } else {
              const errorMsg = typeof messages === 'string' ? messages : 'An unexpected error occurred';
              toast.error(errorMsg);
          }
      } finally {
          setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchStatistics();
    }, [startDate, endDate, dataColumn]);
  
  
  return (
    <div>
         <div className="flex space-x-4 mb-4">
            <input
                type="date"
                value={startDate}
                onChange={(e) => {
                    setStartDate(e.target.value);
                    fetchStatistics(); // Met à jour les statistiques
                }}
                className="border rounded-md px-2 py-1"
            />
            <input
                type="date"
                value={endDate}
                onChange={(e) => {
                    setEndDate(e.target.value);
                    fetchStatistics(); // Met à jour les statistiques
                }}
                className="border rounded-md px-2 py-1"
            />
                                       
            
            <select
                value={dataColumn}
                onChange={(e) => {
                    setDataColumn(e.target.value);
                    fetchStatistics(); // Met à jour les statistiques
                }}
                className="border rounded-md px-2 py-1"
            >
                <option value="ed_courant_phase_1">Courant Phase 1</option>
                <option value="ed_courant_phase_2">Courant Phase 2</option>
                <option value="ed_courant_phase_3">Courant Phase 3</option>
                <option value="ed_tension_composee_uab">Tension Composee UAB</option>
                <option value="ed_tension_composee_ubc">Tension Composee UBC</option>
                <option value="ed_tension_composee_uca">Tension Composee UCA</option>
                <option value="ed_frequency">Frequence</option>
                <option value="ed_facteur_puissance">Facteur de Puissance</option>
                <option value="ed_puissance_active">Puissance Active</option>
                <option value="ed_puissance_reactive">Puissance Reactive</option>
                <option value="ed_energie_active">Énergie Active</option>
                <option value="ed_energie_reactive">Énergie Réactive</option>
            </select>
        </div>


        {loading ? (
                    <div className="text-center py-4">Chargement...</div>
                ) : (
                    <>

                        <div className="font-bold ">
                            Données par jour
                        </div>
                        
                        <Chart
                            options={{
                                chart: {
                                    type: 'line',
                                    zoom: { enabled: true },
                                },
                                stroke: {
                                    curve: 'smooth', // Utiliser 'smooth' pour des courbes arrondies
                                },
                                xaxis: {
                                    categories: statistics.map(stat => {
                                        const date = new Date(stat.date);
                                        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
                                    }),
                                },
                                yaxis: {
                                    min: Math.min(...statistics.map(stat => Math.min(Number(stat.moyenne).toFixed(2), Number(stat.minimum).toFixed(2), Number(stat.maximum).toFixed(2)))) - 2, // Ajustez cette valeur selon vos besoins
                                    max: Math.max(...statistics.map(stat => Math.max(Number(stat.moyenne).toFixed(2), Number(stat.minimum).toFixed(2), Number(stat.maximum).toFixed(2)))) + 10, // Ajustez pour éviter le dépassement
                                },
                                tooltip: {
                                    shared: true,
                                    intersect: false,
                                },
                            }}
                            series={[
                                {
                                    name: 'Moyenne',
                                    data: statistics.map(stat => Number(stat.moyenne).toFixed(2)),
                                },
                                {
                                    name: 'Minimum',
                                    data: statistics.map(stat => Number(stat.minimum).toFixed(2)),
                                },
                                {
                                    name: 'Maximum',
                                    data: statistics.map(stat => Number(stat.maximum).toFixed(2)),
                                },
                            ]}
                            height={400}
                        />


                    </>
                )}

    </div>
  )
}
