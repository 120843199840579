import React from 'react'
import { useAuth } from '../../contexts/auth'
import { NavLink } from 'react-router-dom'

const DropdownMenuItem = () => {

  const { user, logout } = useAuth()

  const logoutSubmit = () => {
    logout()
  }

  return (
    <div>
        <NavLink to="/profile" className="dropdown-item text-sm">Profil</NavLink>
        <NavLink to="/password" tabIndex="-1" className="dropdown-item text-sm">Change password</NavLink>
        <NavLink to="#" onClick={logoutSubmit} tabIndex="-1" className="dropdown-item text-sm text-red-500 bg-red-100 hover:bg-red-200">Sign out</NavLink>
    </div>
  )
}

export default DropdownMenuItem