import React, { useState } from 'react'
import AuthLayout from '../../../components/layouts/AuthLayout'
import SubmitButton from '../../../components/button/SubmitButton'
import ErrorsField from '../../../components/errors/ErrorsField'
import { useForm } from 'react-hook-form'
import axios from '../../../lib/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export const AddEquipment = () => {

  const navigate = useNavigate();
  const { register, formState: { errors }, handleSubmit, } = useForm()
  const [loading, setLoading] = useState(false);



  
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post("sigees/equipment/add_equipment", {
        eq_name                 : data.eq_name,
        eq_type                 : data.eq_type,
        eq_location             : data.eq_location
      });
      toast.success(response.data.response.message)
      navigate('/equipments')

      
    } catch (error) {
        const messages = error?.response?.data?.response?.message;
        if (Array.isArray(messages)) {
            messages.forEach((message) => toast.error(message.msg));
        } else {
            const errorMsg = typeof messages === 'string' ? messages : 'An unexpected error occurred';
            toast.error(errorMsg);
        }
    } finally {
        setLoading(false);
    }
  }

  return (
    <AuthLayout>
        <div className="">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Ajouter un équipement</h1>
          
        </div>

        <div className='flex justify-center items-center h-full  max-w-lg mt-7'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-3"
            >
              <div >

                <label className="font-medium">
                  Nom équipement
                </label>
                <input
                  {...register('eq_name', { required: true })}
                  type="text"            
                  className={`w-full mt-2 px-3 py-2 ${errors.eq_name && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
                />
                <ErrorsField errors={errors.eq_name} message={"Name is required"} />
              </div>

              <div>

                <label className="font-medium">
                  Type d'équipement
                </label>
                <select className={` w-full mt-2 px-3 py-2 ${errors.eq_type && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
                  {...register('eq_type', { required: true })}
                >
                  <option value={"sensor"}>Sensor</option>
                  <option value={"controller"}>Controller</option>
                  <option value={"monitor"}>Monitor</option>
                  <option value={"gateway"}>Gateway</option>
                </select>
                <ErrorsField errors={errors.eq_name} message={"Type is required"} />
              </div>
              
              <div>
                <label className="font-medium  flex justify-between">
                  Localisation 
                </label>
                <input
                  {...register('eq_location', { required: true })}
                  type="text"         
                  className={`w-full mt-2 px-3 py-2 ${errors.eq_location && "border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
                />
                <ErrorsField errors={errors.eq_location} message={"Localisation is required"} />
                
              </div>
              <SubmitButton text={"Ajouter"} disabled />
              
            </form>

        </div>

        </div>
    </AuthLayout>
  )
}
