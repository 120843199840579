import React from 'react'
import AuthLayout from '../../components/layouts/AuthLayout'

export const Souscription = () => {
  return (
      <AuthLayout>
        <div>Souscription</div>
      </AuthLayout>
  )
}
