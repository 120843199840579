import React from 'react'
import AuthLayout from '../../components/layouts/AuthLayout'

export const Alert = () => {
  return (
    <AuthLayout>
        <div>Alerts</div>
    </AuthLayout>
  )
}
