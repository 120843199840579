import React, { useEffect } from 'react'
import DropdownMenuItem from '../components/DropdownMenuItem'
import { useAuth } from '../../contexts/auth'
import LoadingComponent from '../components/LoadingComponent'
import { Link } from 'react-router-dom'
import LogoApp from '../components/LogoApp'
import { AlignJustify, Bell, CreditCard, LayoutDashboardIcon, MessageSquare, Settings, User, Users, Zap } from 'lucide-react'


const AuthLayout = ({children}) => {
    
    const { user, isAuthenticated, loading, logout } = useAuth();

    useEffect(() => {
        if (!loading && !isAuthenticated) {
            logout(); 
        }
    }, [isAuthenticated, loading, logout]);

    if (loading) {
        return <LoadingComponent />;
    }

  return (
        <div>     
            { isAuthenticated && 
                <div>        
                    <div className="navbar navbar-sticky rounded-lg">
                        <div className="navbar-start">
                            <label htmlFor="sidebar-mobile-fixed" className="btn btn-square btn-ghost sm:hidden">
                                <AlignJustify />                                
                            </label>
                        </div>
                        <div className="navbar-end">
                            <select className="select w-16 rounded-full">
                                <option>EN</option>
                                <option>FR</option>
                            </select>                  
                            <div className="avatar avatar-ring avatar-md">
                                <div className="dropdown-container">
                                    <div className="dropdown">
                                        <label className="btn btn-square btn-ghost flex cursor-pointer px-0" tabIndex="0">
                                            <User />                                            
                                        </label>
                                        <div className="dropdown-menu dropdown-menu-bottom-left">
                                            <DropdownMenuItem />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row sm:gap-10">
                        <div className="sm:w-full sm:max-w-[16rem]">
                            <input type="checkbox" id="sidebar-mobile-fixed" className="sidebar-state" />
                            <label htmlFor="sidebar-mobile-fixed" className="sidebar-overlay"></label>
                            <aside className="sidebar sidebar-fixed-left sidebar-mobile h-full justify-start max-sm:fixed max-sm:-translate-x-full">
                                <section className="sidebar-title items-center p-4 bg-gray-800">                        
                                    <div className="flex flex-col">
                                        <LogoApp showLink={true} />
                                    </div>
                                </section>
                                <section className="sidebar-content bg-gray-800 text-white">
                                    <nav className="menu rounded-md">
                                        <section className="menu-section px-2">
                                            <span className="menu-title">Main menu</span>
                                            <ul className="menu-items">
                                                <Link to="/dashboard" className="menu-item text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                                    <LayoutDashboardIcon />
                                                    <span>Dashboard</span>
                                                </Link>

                                                <Link to="/equipments" className="menu-item text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                                    <Zap />
                                                    <span>Equipements</span>
                                                </Link>
                                                <Link to="/alerts" className="menu-item text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                                    <Bell />
                                                    <span>Alerte</span>
                                                </Link>

                                                <Link to="/souscriptions" className="menu-item  text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                                    <CreditCard />
                                                    <span>Souscription</span>
                                                </Link>
                                                <Link to="/users" className="menu-item  text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                                    <Users />
                                                    <span>Utilisateurs</span>
                                                </Link>
                                                <Link to="/settings" className="menu-item  text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                                    <Settings />
                                                    <span>Settings</span>
                                                </Link>
                                                                                
                                                
                                            </ul>
                                        </section>
                                        
                                    </nav>
                                </section>
                                <section className="sidebar-footer justify-end pt-2 bg-gray-800">
                                        
                                    <li className="dropdown z-50 flex  cursor-pointer hover:bg-gray-4 w-full  text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                        <label className="whites mx-2 flex cursor-pointer p-0 hover:bg-gray-4" tabIndex="0">
                                            <div className="flex flex-row gap-4 p-4">                                    
                                                <User />
                                                <span>
                                                    {`${user.nom} ${user.prenoms}`}
                                                </span>
                                            </div>
                                        </label>
                                        <div className="sm:dropdown-menu-right-top dropdown-menu-top-center dropdown-menu ml-2 text-[#3dcd58]">
                                            <DropdownMenuItem />
                                        </div>
                                    </li>
                                    <Link to="/get_support" className="menu-item mb-7 pl-7 text-white hover:text-[#3dcd58] active:text-[#3dcd58]">
                                        <MessageSquare />
                                        <span>Get support</span>
                                    </Link>
                                </section>
                                <section className="bg-gray-800   text-white " >
                                    <Link to="https://hticnet.com" className='mb-7 pl-7'>
                                        &copy; 2024 Htic-Networks                                    
                                    </Link>
                                </section>
                                    
                            </aside>
                        </div>
                        <div className="flex w-full flex-col ">
                            <div className="mt-12 grid md:grid-cols-none bg-white mb-7 w-full">
                              <div className=" mt-5">
                                  {children}
                              </div>
                            </div>                             
                        </div>
                    </div>
                </div>            
            }
        </div>
  )
}

export default AuthLayout