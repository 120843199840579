
import React, { useEffect } from 'react'
import LoadingComponent from '../components/LoadingComponent'
import { useAuth } from '../../contexts/auth'
import { Link, useNavigate } from 'react-router-dom'
import LogoApp from '../components/LogoApp'

const RegistrationLayout = ({children, link, title, question, linkTitle}) => {
  const { loading, isAuthenticated } = useAuth()

  
  const navigate = useNavigate();

  // // This useEffect hook will check if the user is authenticated and redirect if not.
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [ isAuthenticated, navigate ]);

  // // While loading or not authenticated, you can show a loading component or a message.
  if (loading) {
    return <LoadingComponent />;
  }

  
  return (
    <main className="w-full  flex flex-col items-center justify-center bg-gray-50 sm:px-4"> 
      {!isAuthenticated && 
        <div className="w-full space-y-6 text-gray-600 sm:max-w-md">
          <div className="text-center">
            <LogoApp showLink={false} />
            <div className="mt-5 space-y-2">
              <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">{title}</h3>
              <div className="">{question}<Link to={link} className="font-medium text-[#009530] hover:text-[#3dcd58]">{linkTitle}</Link></div>
            </div>
          </div>
          <div className="bg-[#FFFBFF] shadow p-4 py-6 space-y-8 sm:p-6 sm:rounded-lg">
            
            <div className="relative">
              <span className="block w-full h-px bg-gray-300"></span>
              <div className="inline-block w-fit text-sm bg-white px-2 absolute -top-2 inset-x-0 mx-auto">Or continue with</div>
            </div>
            
            {children}
            
          </div>
          <div className="text-center">

          </div>
        </div>      
      } 
    </main>
  )
}

export default RegistrationLayout