import React from 'react'

const SubmitButton = ({text}) => {
  return (
    <div>
        <button
          className=" mt-5 w-full px-4 py-2 text-white font-medium bg-[#009530] hover:bg-[#3dcd58] active:bg-[#3dcd58] rounded-lg duration-150"
        >
          {text}
      </button>
    </div>
  )
}

export default SubmitButton