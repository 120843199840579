import React, { useState } from 'react'
import { useAuth } from '../../contexts/auth'
import { useForm } from 'react-hook-form'
import RegistrationLayout from '../../components/layouts/RegistrationLayout'
import ErrorsField from '../../components/errors/ErrorsField'
import { Link } from 'react-router-dom'
import SubmitButton from '../../components/button/SubmitButton'

export const Login = () => {

  const { register, formState: { errors }, handleSubmit, } = useForm()
  const { login } = useAuth()
  const [ errs, setErrs ] = useState([])
  const [ isLoading, setIsLoading ] = useState([])


  const onSubmit = async (data) => {
    login(data.email, data.password)
  }

  return (
    <RegistrationLayout 
      link={"/register"}
      linkTitle={"Sign up"}
      title={"Log in to your account"}
      question={"Don't have an account? "}
    >
      
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5"
      >
        <div>
          
          <label className="font-medium">
            Email
          </label>
          <input
            {...register('email', { required: true })}
            type="email"            
            className={`w-full mt-2 px-3 py-2 ${errors.email && " border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.email} message={"Email is required"} />
        </div>
        <div>
          <label className="font-medium  flex justify-between">
            Password <Link to="/email" className="hover:text-[#3dcd58] text-[#009530]">Forgot password?</Link>
          </label>
          <input
            {...register('password', { required: true })}
            type="password"         
            className={`w-full mt-2 px-3 py-2 ${errors.password && "border-2 border-red-500"} text-gray-600 bg-gray-100 outline-none border focus:border-[#3dcd58] shadow-sm rounded-lg`}
          />
          <ErrorsField errors={errors.password} message={"Password is required"} />
          
        </div>
        <SubmitButton text={"Sign in"} disabled />
        
      </form>
    </RegistrationLayout>
  )
}
