
import React from 'react'
import AuthLayout from '../../components/layouts/AuthLayout'

export const Dashboard = () => {
  return (
    <AuthLayout>
        <div>Dashboard</div>
    </AuthLayout>
  )
}
