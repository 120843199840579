import React, { useEffect, useState } from 'react'
import axios from '../../../../lib/axios'; // Assurez-vous que le chemin est correct
import { toast } from 'react-toastify';
import { ExportStatsByDayModal } from '../ExportDatas/ExportStatsByDayModal';


export const StatsTabDay = ({ equipment }) => {
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [order, setOrder] = useState('DESC');
    const [startDate, setStartDate] = useState('2023-01-01'); // À adapter selon vos besoins
    const [endDate, setEndDate] = useState('2023-03-01');   // À adapter selon vos besoins
    const [dataColumn, setDataColumn] = useState('ed_energie_reactive'); // Valeur par défaut
    const [global, setGlobal] = useState([]); // Valeur par défaut

  const fetchStatistics = async (page) => {
    try {
        setLoading(true);
        const response = await axios.get(`sigees/statistics/by_day/${equipment.eq_code}/startDate/${startDate}/endDate/${endDate}/page/${page}/limit/${limit}/order/${order}/data_column/${dataColumn}`);
        setStatistics(response.data.response.data.datas_by_day); // Assurez-vous que la structure des données est correcte
        setGlobal(response.data.response.data.global[0]); // Assurez-vous que la structure des données est correcte
        setTotalPages(Math.ceil(response.data.response.data.totalPages));
    } catch (error) {
        const messages = error?.response?.data?.response?.message;
        if (Array.isArray(messages)) {
            messages.forEach((message) => toast.error(message.msg));
        } else {
            const errorMsg = typeof messages === 'string' ? messages : 'An unexpected error occurred';
            toast.error(errorMsg);
        }
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistics(currentPage);
  }, [currentPage, limit, order, startDate, endDate, dataColumn]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
        <div>
        <div className="flex space-x-4 mb-4">
            <input
                type="date"
                value={startDate}
                onChange={(e) => {
                    setStartDate(e.target.value);
                    fetchStatistics(currentPage); // Met à jour les statistiques
                }}
                className="border rounded-md px-2 py-1"
            />
            <input
                type="date"
                value={endDate}
                onChange={(e) => {
                    setEndDate(e.target.value);
                    fetchStatistics(currentPage); // Met à jour les statistiques
                }}
                className="border rounded-md px-2 py-1"
            />
            <input
                type="number"
                min="5"
                max="1000"
                value={limit}
                onChange={(e) => {
                    const newLimit = Number(e.target.value);
                    if (newLimit <= 1000) {
                        setLimit(newLimit);
                        fetchStatistics(currentPage)
                    }
                }}
                className="border rounded-md px-2 py-1"
                placeholder="Limit (5-1000)"
                />                              
            
            <select
                value={dataColumn}
                onChange={(e) => {
                    setDataColumn(e.target.value);
                    fetchStatistics(currentPage); // Met à jour les statistiques
                }}
                className="border rounded-md px-2 py-1"
            >
                <option value="ed_courant_phase_1">Courant Phase 1</option>
                <option value="ed_courant_phase_2">Courant Phase 2</option>
                <option value="ed_courant_phase_3">Courant Phase 3</option>
                <option value="ed_tension_composee_uab">Tension Composee UAB</option>
                <option value="ed_tension_composee_ubc">Tension Composee UBC</option>
                <option value="ed_tension_composee_uca">Tension Composee UCA</option>
                <option value="ed_frequency">Frequence</option>
                <option value="ed_facteur_puissance">Facteur de Puissance</option>
                <option value="ed_puissance_active">Puissance Active</option>
                <option value="ed_puissance_reactive">Puissance Reactive</option>
                <option value="ed_energie_active">Énergie Active</option>
                <option value="ed_energie_reactive">Énergie Réactive</option>
            </select>
            <select
                value={order}
                onChange={(e) => {
                    setOrder(e.target.value);
                    fetchStatistics()
                }}
                className="border rounded-md px-2 py-1"
                >
                <option value="DESC">DESC</option>
                <option value="ASC">ASC</option>
            </select>  
            <ExportStatsByDayModal data={statistics} equipment={equipment}  />
        </div>

      {loading ? (
        <div className="text-center py-4">Chargement...</div>
      ) : (
        <>

            <div className="bg-white rounded-lg shadow p-3 mb-3">
                <div className="font-bold ">
                    Global
                </div>
                <div className="flex flex-wrap justify-between">
                    <div className="w-1/2 sm:w-1/3 p-2">
                        <p className="text-sm font-medium text-gray-500">Moyenne</p>
                        <p className="text-sm font-semibold text-gray-900">{!isNaN(Number(global?.moyenne)) ? Number(global?.moyenne).toFixed(2) : 'N/A'}</p>
                    </div>
                    <div className="w-1/2 sm:w-1/3 p-2">
                        <p className="text-sm font-medium text-gray-500">Minimum</p>
                        <p className="text-sm font-semibold text-gray-900">{global?.minimum}</p>
                    </div>
                    <div className="w-1/2 sm:w-1/3 p-2">
                        <p className="text-sm font-medium text-gray-500">Maximum</p>
                        <p className="text-sm font-semibold text-gray-900">{global?.maximum}</p>
                    </div>
                    
                </div>
            </div>



            <div className="font-bold ">
                Données par jour
            </div>
            <table className="min-w-full bg-white border border-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">index</th>
                        <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Moyenne</th>
                        <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Minimum</th>
                        <th className="px-2 py-1 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Maximum</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {statistics?.map((stat, index) => (
                    <tr key={stat.date}>
                        <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{index + 1}</td> {/* Numéro d'enregistrement */}
                        <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900"> {new Date(stat.date).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                        <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{!isNaN(Number(stat.moyenne)) ? Number(stat.moyenne).toFixed(2) : 'N/A'}</td>
                        <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{stat.minimum}</td>
                        <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-900">{stat.maximum}</td>
                    </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-center items-center space-x-2 mt-4 mb-8">
            <button
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 border rounded-md ${
                currentPage === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Première
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 border rounded-md ${
                currentPage === 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Précédente
            </button>
            <span className="px-4 py-2">
              Page {currentPage} sur {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 border rounded-md ${
                currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Suivante
            </button>
            <button
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 border rounded-md ${
                currentPage === totalPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50'
              }`}
            >
              Dernière
            </button>
          </div>

        </>
      )}
    </div>
    </div>
  )
}
