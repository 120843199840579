import React from 'react'
import { Link } from 'react-router-dom'

const LogoApp = ({ showLink = true }) => {
  return (
  <div>
       {showLink ? ( // Affiche le lien si showLink est true
          <Link to="/dashboard" className="text-[#009530] text-2xl mx-10 font-extrabold">
              SIGEES
          </Link>
        ) : ( // Affiche le logo sans lien si showLink est false
          <div className="text-[#009530] text-2xl mx-10 font-extrabold">
              SIGEES
          </div>
        )}
  </div>
  )
}

export default LogoApp